import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Card from "../../components/Card/Card";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/Loader";
import { useToasts } from "react-toast-notifications";
import { ReactTabulator } from "react-tabulator";
import {
  getTrunkListForOneUser,
  getTrunkGlobalInfo,
  getTrunkMysql,
  getSoldeTrunkMysql,
} from "../../redux/actions/trunkActions";
import { searchOptimizedData } from "../../utils/recherche";
import ModalParametrage from "./ModalParametrage";
import ModalAssignment from "./ModalAssignment";
import ModalAlert from "./ModalAlert";
import ModalListeNoireEntrante from "./ModalListeNoireEntrante";
import ModalListeNoireSortante from "./ModalListeNoireSortante";
import moment from "moment";
import FiltreTrunk from "./FiltreTrunk";
const Trunk = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const dateAujourdhui = moment().utc().local().add(1, "days");
  const startDateFormate = dateAujourdhui.format("YYYY-MM-DDTHH:mm:ss");
  const dateOnWeekBefore = moment(dateAujourdhui).subtract(15, "days");
  const dateFormateeOneWeeBefore = dateOnWeekBefore.format(
    "YYYY-MM-DDTHH:mm:ss"
  );
  const [endDate, setEndDate] = useState(startDateFormate);
  const [startDate, setStartDate] = useState(dateFormateeOneWeeBefore);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  const listTrunk = useSelector((state) => state.listTrunk);
  const { trunk_list_loading, trunks, trunk_list_error } = listTrunk;
  const trunkGLobalInfo = useSelector((state) => state.trunkGlobalInfo);
  const { trunk_global_info_loading, trunk_global_info_error, dataGlobal } =
    trunkGLobalInfo;

  if (!userInfo?.token) {
    navigate("/login");
  }
  const [dataRequest, setDataRequest] = useState([]);
  const [bool, setBool] = useState(false);
  const [parametrage, setParametrage] = useState(false);
  const [assignment, setAssignment] = useState(false);
  const [alert, setAlert] = useState(false);
  const [isListNoirModalEntrante, setIsListNoirModalEntrante] = useState(false);
  const [isListNoirModalSortante, setIsListNoirModalSortante] = useState(false);
  const [listAlert, setlistAlert] = useState([]);
  const [trunkId, setTrunkId] = useState();
  const [selectedNumTrunk, setSelectedNumTrunk] = useState([]);
  const [trunkToUpdate, setTrunkToUpdate] = useState();
  const fieldsToFilter = [
    "consommation",
    "label",
    "moneyBudget",
    "numeros",
    "timeBudget",
  ];

  //simple input react tabulator
  const simpleInput = (cell) => {
    const input = document.createElement("input");
    input.type = "text";
    input.style =
      "width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;background-color:white";
    input.defaultValue = cell.getValue();
    input.disabled = true;
    const container = document.createElement("div");
    container.style = "display:flex;align-items:center;width:100%";
    container.appendChild(input);
    return container;
  };
  //simple input budget react tabulator
  const simpleInputBudget = (cell) => {
    const value = cell.getValue();
    const input = document.createElement("input");
    input.type = "text";
    input.style =
      "width:100%;border:none;padding:11px 22px;font-size:16px;font-weight:600;color:#334155;background-color:white";
    input.defaultValue =
      value && value.length > 0 ? value.map((el) => ` ${el}€ `) : "---";
    input.disabled = true;
    const container = document.createElement("div");
    container.style = "display:flex;align-items:center;width:100%";
    container.appendChild(input);
    return container;
  };

  //Boutton Alert
  const buttonAlerte = (cell) => {
    const data = cell.getData();
    const btn = document.createElement("button");
    btn.className = "btn";
    if (data.todayAlerts && data.todayAlerts.length > 0) {
      btn.style =
        "background-color:white;color:red;border-color:red;cursor:pointer";
      const icon = document.createElement("icon");
      icon.className = "bi bi-exclamation-circle-fill";
      const span = document.createElement("span");
      span.style = "font-size:20px;margin-right:5px";
      span.innerText = `${data?.todayAlerts?.length}`;
      btn.appendChild(span);
      btn.appendChild(icon);
    } else {
      btn.style =
        "background-color:white;color:#61CE70;border-color:#61CE70;padding:5px 22px;cursor:pointer";
      const icon = document.createElement("icon");
      icon.className = "bi bi-check-circle-fill";
      btn.appendChild(icon);
    }
    btn.addEventListener("click", function (ev) {
      setAlert(true);
      setlistAlert(data?.todayAlerts ? data.todayAlerts : []);
    });
    const container = document.createElement("div");
    container.appendChild(btn);
    return container;
  };
  //Boutton liste noir
  const buttonListeNoire = (cell) => {
    const data = cell.getData();
    const btn = document.createElement("button");
    btn.className = "btn";
    btn.style =
      "background-color:white;color:green;border-color:green;padding:5px 10px;cursor:pointer";
    const icon = document.createElement("icon");
    icon.className = "bi bi-arrow-down-left";
    btn.appendChild(icon);
    btn.addEventListener("click", function (ev) {
      setIsListNoirModalEntrante(true);
      setTrunkId(data._id);
    });
    const btn2 = document.createElement("button");
    btn2.className = "btn";
    btn2.style =
      "background-color:white;color:red;border-color:red;padding:5px 10px;cursor:pointer;margin-left:10px;";
    const icon2 = document.createElement("icon");
    icon2.className = "bi bi-arrow-up-right";
    btn2.appendChild(icon2);
    btn2.addEventListener("click", function (ev) {
      setIsListNoirModalSortante(true);
      setTrunkId(data._id);
    });
    const container = document.createElement("div");
    container.style = "display:flex;";
    container.appendChild(btn);
    container.appendChild(btn2);
    return container;
  };
  //boutton assignment
  const buttonAssignement = (cell) => {
    const data = cell.getData();
    const btn = document.createElement("button");
    btn.className = "btn";
    btn.style = "background-color:#61CE70;color:white";
    const icon = document.createElement("icon");
    icon.className = "bi bi-link-45deg";
    btn.appendChild(icon);
    btn.addEventListener("click", function (ev) {
      setAssignment(true);
      setSelectedNumTrunk(data.numeros ? data.numeros : []);
    });
    const container = document.createElement("div");
    container.appendChild(btn);
    return container;
  };
  //boutton parametrage
  const buttonParametrage = (cell) => {
    const data = cell.getData();
    const btn = document.createElement("button");
    btn.className = "btn";
    btn.style = "background-color:#61CE70;color:white";
    const icon = document.createElement("icon");
    icon.className = "bi bi-plus";
    btn.appendChild(icon);
    btn.addEventListener("click", function (ev) {
      setParametrage(true);
      setTrunkToUpdate(data);
    });
    const container = document.createElement("div");
    container.appendChild(btn);
    return container;
  };
  //Colonne react tabulator trunk
  const colonneTrunk = [
    { title: "Label", minWidth: 150, field: "label", formatter: simpleInput },
    {
      title: "consommation",
      minWidth: 150,
      field: "consommation",
      formatter: simpleInput,
    },
    {
      title: "budget (Alerte)",
      minWidth: 150,
      field: "moneyBudget",
      formatter: simpleInputBudget,
    },
    {
      title: "Liste noire",
      width: 120,
      field: "",
      formatter: buttonListeNoire,
    },
    { title: "Status", width: 120, field: "", formatter: buttonAlerte },
    { title: "Numéro", width: 120, field: "", formatter: buttonAssignement },
    { title: "Plus", width: 120, field: "", formatter: buttonParametrage },
  ];

  //Recuperation de tous les listes de trunk
  useEffect(() => {
    const momentDateDebut = moment(startDate, "YYYY-MM-DD HH:mm:ss");
    const momentDateFin = moment(endDate, "YYYY-MM-DD HH:mm:ss");
    if (momentDateDebut.isSameOrBefore(momentDateFin)) {
      dispatch(
        getTrunkListForOneUser(
          `calldate[gte]=${startDate}&calldate[lte]=${endDate}`
        )
      );
      dispatch(
        getTrunkGlobalInfo(
          `calldate[gte]=${startDate}&calldate[lte]=${endDate}`
        )
      );
    } else {
      alert("la date de debut doit etre inférieur ou égal à la date de fin");
    }
  }, [bool, endDate, startDate]);

  //gestion des erreur
  useEffect(() => {
    if (trunk_list_error) {
      addToast(trunk_list_error, {
        appearance: "error",
        autoDismiss: true,
      });
    }
    if (trunk_global_info_error) {
      addToast(trunk_global_info_error, {
        appearance: "error",
        autoDismiss: true,
      });
    }
  }, [trunk_list_error, trunk_global_info_error]);

  useEffect(() => {
    if (trunks && trunks.length > 0) {
      setDataRequest(trunks);
    } else {
      setDataRequest([]);
    }
  }, [trunks]);

  //Information pour l'utilisateur qui possede uniquement un serveur trunk
  const [infoCDR, setInfoCDR] = useState([]);
  const [isOnlyTrunk, setIsOnlyTrunk] = useState(true);
  const [consommationTrunk, setConsommationTrunk] = useState("0");
  const [durationTrunk, setDurationTrunk] = useState("0 sec");
  const [solde, setSolde] = useState("0");
  const [wait, setWait] = useState(false);
  const [wait2, setWait2] = useState(false);
  //Colonne react tabulator trunk
  const colonneInfoCDR = [
    { title: "date", minWidth: 150, field: "calldate", formatter: simpleInput },
    {
      title: "source",
      minWidth: 150,
      field: "src",
      formatter: simpleInput,
    },
    {
      title: "destination",
      minWidth: 150,
      field: "dst",
      formatter: simpleInput,
    },
    {
      title: "disposition",
      width: 120,
      field: "disposition",
      formatter: simpleInput,
    },
    {
      title: "durée",
      width: 120,
      field: "billsec",
      formatter: simpleInput,
    },
    { title:"ID", width: 120, field: "uniqueid", formatter: simpleInput },
  ];
  useEffect(() => {
    if (userInfo?.data?.user?.isOnlyTrunk) {
      setIsOnlyTrunk(true);
    } else {
      setIsOnlyTrunk(false);
    }
  }, []);
  useEffect(() => {
    setWait(true);
    getTrunkMysql(userInfo?.token, startDate, endDate)
      .then((res) => {
        console.log(res);
        setWait(false);
        if (res.data && res.data.length > 0) {
          setInfoCDR(res.data);
          setConsommationTrunk(res.consommation);
          setDurationTrunk(res.duration);
        } else {
          setInfoCDR([]);
        }
      })
      .catch((err) => {
        console.log(err);
        setWait(false);
      });
  }, [endDate, startDate]);
  useEffect(() => {
    setWait2(true);
    getSoldeTrunkMysql(userInfo?.token)
      .then((res) => {
        if (res.solde) {
          setSolde(res.solde);
        }
        setWait2(false)
      })
      .catch((err) => {
        console.log(err);
        setWait2(false)
      });
  }, []);
  return (
    <>
      <div className="container">
        <div className="row d-flex flex-wrap justify-content-between align-items-center">
          <div className="col-12">
            <h5 className="current-route text-capitalize">/ Trunk</h5>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          {isOnlyTrunk ? (
            <>
              {wait&&wait2 ? (
                <Loader />
              ) : (
                <>
                  <Card
                    cardTitle="Durée appel"
                    cardValeur={durationTrunk ? durationTrunk : "0 sec"}
                  />
                  <Card
                    cardTitle="Consommations"
                    cardValeur={
                      consommationTrunk ? `${consommationTrunk}€` : "0 €"
                    }
                  />
                  <Card
                    cardTitle="solde"
                    cardValeur={solde ? `${solde} €` : "0 €"}
                  />
                </>
              )}
            </>
          ) : (
            <>
              {trunk_global_info_loading ? (
                <Loader />
              ) : (
                <Card
                  cardTitle="Alerte"
                  cardValeur={dataGlobal?.alerts ? dataGlobal.alerts : "0"}
                />
              )}
              {trunk_global_info_loading ? (
                <Loader />
              ) : (
                <Card
                  cardTitle="Trunk"
                  cardValeur={dataGlobal?.trunks ? dataGlobal.trunks : "0"}
                />
              )}
              {trunk_global_info_loading ? (
                <Loader />
              ) : (
                <Card
                  cardTitle="Consommation"
                  cardValeur={
                    dataGlobal?.consommation ? dataGlobal.consommation : "0 min"
                  }
                />
              )}
            </>
          )}
        </div>
        {isOnlyTrunk ? (
          <>
            <div className="tabHistorique">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  padding: "0px 0px 0px 40px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="titreTabulator">
                    Historiques Appels -{" "}
                    {infoCDR?.length ? infoCDR.length : "0"} Appel(s)
                  </span>
                </div>
                <div
                  className="champRecherche"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <button
                    className="btn btn-sm"
                    style={{
                      backgroundColor: "#6cc070 ",
                      color: "white",
                      marginRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#filtreTrunk"
                  >
                    <i class="bi bi-filter" style={{ fontSize: "14px" }}></i>
                    <span>Filtres</span>{" "}
                  </button>
                  <span
                    style={{
                      padding: "8px 12px 8px 12px",
                      backgroundColor: "rgba(226, 232, 240, 0.6)",
                      borderRadius: "8px",
                      height: "40px",
                      display: "flex",
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#818CF8"
                        style={{ width: "18px", height: "18px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="rechercheTabulator"
                      placeholder="Recherche"
                      onChange={(ev) => {
                        const dataSearch = searchOptimizedData(
                          ev.target.value,
                          infoCDR,
                          fieldsToFilter
                        );
                        setInfoCDR(dataSearch);
                      }}
                    />
                  </span>
                </div>
              </div>
              {wait ? (
                <Loader />
              ) : (
                <>
                  <ReactTabulator
                    data={infoCDR}
                    columns={colonneInfoCDR}
                    layout={"fitColumns"}
                    options={{
                      locale: true,
                      pagination: "local",
                      paginationSize: 5,
                      langs: {
                        fr: {
                          pagination: {
                            first: "Premier",
                            first_title: "Première",
                            last: "Dernier",
                            last_title: "Dernière",
                            prev: "Précédent",
                            prev_title: "Précédente",
                            next: "Suivant",
                            next_title: "Page suivante",
                            page_size: "Taille de page",
                            page_title: "Afficher la page",
                            all: "Tous",
                          },
                        },
                      },
                    }}
                  />
                </>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="tabHistorique">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                  padding: "0px 0px 0px 40px",
                }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span className="titreTabulator">
                    Listes Trunk -{" "}
                    {dataRequest?.length ? dataRequest.length : "0"} Trunk(s)
                  </span>
                </div>
                <div
                  className="champRecherche"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <button
                    className="btn btn-sm"
                    style={{
                      backgroundColor: "#6cc070 ",
                      color: "white",
                      marginRight: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                    data-bs-toggle="offcanvas"
                    data-bs-target="#filtreTrunk"
                  >
                    <i class="bi bi-filter" style={{ fontSize: "14px" }}></i>
                    <span>Filtres</span>{" "}
                  </button>
                  <span
                    style={{
                      padding: "8px 12px 8px 12px",
                      backgroundColor: "rgba(226, 232, 240, 0.6)",
                      borderRadius: "8px",
                      height: "40px",
                      display: "flex",
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="#818CF8"
                        style={{ width: "18px", height: "18px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                        />
                      </svg>
                    </span>
                    <input
                      type="text"
                      className="rechercheTabulator"
                      placeholder="Recherche"
                      onChange={(ev) => {
                        const dataSearch = searchOptimizedData(
                          ev.target.value,
                          dataRequest,
                          fieldsToFilter
                        );
                        setDataRequest(dataSearch);
                      }}
                    />
                  </span>
                </div>
              </div>
              {trunk_list_loading ? (
                <Loader />
              ) : (
                <>
                  <ReactTabulator
                    data={dataRequest}
                    columns={colonneTrunk}
                    layout={"fitColumns"}
                    options={{
                      locale: true,
                      pagination: "local",
                      paginationSize: 5,
                      langs: {
                        fr: {
                          pagination: {
                            first: "Premier",
                            first_title: "Première",
                            last: "Dernier",
                            last_title: "Dernière",
                            prev: "Précédent",
                            prev_title: "Précédente",
                            next: "Suivant",
                            next_title: "Page suivante",
                            page_size: "Taille de page",
                            page_title: "Afficher la page",
                            all: "Tous",
                          },
                        },
                      },
                    }}
                  />
                </>
              )}
            </div>
          </>
        )}
      </div>
      <FiltreTrunk
        endDate={endDate}
        startDate={startDate}
        setEndDate={setEndDate}
        setStartDate={setStartDate}
      />
      {parametrage && (
        <ModalParametrage
          show={parametrage}
          onHide={() => {
            setParametrage(false);
          }}
          trunk={trunkToUpdate}
          bool={bool}
          setBool={setBool}
          token={userInfo.token}
        />
      )}
      {assignment && (
        <ModalAssignment
          show={assignment}
          selectedNumTrunk={selectedNumTrunk}
          onHide={() => {
            setAssignment(false);
          }}
        />
      )}
      {alert && (
        <ModalAlert
          show={alert}
          onHide={() => {
            setAlert(false);
          }}
          listAlert={listAlert}
        />
      )}
      {isListNoirModalEntrante && (
        <ModalListeNoireEntrante
          show={isListNoirModalEntrante}
          onHide={() => {
            setIsListNoirModalEntrante(false);
          }}
          _id={trunkId}
          token={userInfo.token}
          user={userInfo.data?.user}
        />
      )}
      {isListNoirModalSortante && (
        <ModalListeNoireSortante
          show={isListNoirModalSortante}
          onHide={() => {
            setIsListNoirModalSortante(false);
          }}
          _id={trunkId}
          token={userInfo.token}
          user={userInfo.data?.user}
        />
      )}
    </>
  );
};

export default Trunk;
