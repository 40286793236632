// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-create-budge{
    width: auto;
}
.right-menu {
    width: 400px;
    height: 100%;
    position: fixed;
    top: 0;
    right: -400px;
    transition: right 0.3s ease;
    background-color: #F0F0F2;
    color: white;
    z-index: 1000;
  }
  
  .right-menu.open {
    right: 0;
  }
  .toggle-button {
    background-color: #444;
    color: white;
    padding: 10px;
    cursor: pointer;
    width: 100%;
  }
  
  .menu-modal {
    margin-left: auto;
  }
  
  .menu-headers {
    display: flex;
    align-items: center;
  }
  
  .toggle-buttons {
    margin-right: 320px; 
    margin-top: 10px;
    margin-left: 10px;
    color: black;
  }
  
  .text-filtre { 
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;
    color: black
  }
  .button-filtre{ 
    justify-content: space-between;
  }
 
  .button-filtre-nature{
    border-color: aqua;
    color: aqua;
  }`, "",{"version":3,"sources":["webpack://./src/screens/Demandes/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;AACA;IACI,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,MAAM;IACN,aAAa;IACb,2BAA2B;IAC3B,yBAAyB;IACzB,YAAY;IACZ,aAAa;EACf;;EAEA;IACE,QAAQ;EACV;EACA;IACE,sBAAsB;IACtB,YAAY;IACZ,aAAa;IACb,eAAe;IACf,WAAW;EACb;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,iBAAiB;IACjB,YAAY;EACd;;EAEA;IACE,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB;EACF;EACA;IACE,8BAA8B;EAChC;;EAEA;IACE,kBAAkB;IAClB,WAAW;EACb","sourcesContent":[".modal-create-budge{\n    width: auto;\n}\n.right-menu {\n    width: 400px;\n    height: 100%;\n    position: fixed;\n    top: 0;\n    right: -400px;\n    transition: right 0.3s ease;\n    background-color: #F0F0F2;\n    color: white;\n    z-index: 1000;\n  }\n  \n  .right-menu.open {\n    right: 0;\n  }\n  .toggle-button {\n    background-color: #444;\n    color: white;\n    padding: 10px;\n    cursor: pointer;\n    width: 100%;\n  }\n  \n  .menu-modal {\n    margin-left: auto;\n  }\n  \n  .menu-headers {\n    display: flex;\n    align-items: center;\n  }\n  \n  .toggle-buttons {\n    margin-right: 320px; \n    margin-top: 10px;\n    margin-left: 10px;\n    color: black;\n  }\n  \n  .text-filtre { \n    margin-top: 10px;\n    margin-left: 10px;\n    margin-bottom: 10px;\n    color: black\n  }\n  .button-filtre{ \n    justify-content: space-between;\n  }\n \n  .button-filtre-nature{\n    border-color: aqua;\n    color: aqua;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
