// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dans votre fichier CSS */
.row-custom {
    margin-left: -8px; /* ou ajustez selon vos besoins */
    margin-right: -8px; /* ou ajustez selon vos besoins */
  }
  
  .col-custom {
    padding-left: 8px; /* ou ajustez selon vos besoins */
    padding-right: 8px; /* ou ajustez selon vos besoins */
  }
  `, "",{"version":3,"sources":["webpack://./src/screens/Numero/style.css"],"names":[],"mappings":"AAAA,2BAA2B;AAC3B;IACI,iBAAiB,EAAE,iCAAiC;IACpD,kBAAkB,EAAE,iCAAiC;EACvD;;EAEA;IACE,iBAAiB,EAAE,iCAAiC;IACpD,kBAAkB,EAAE,iCAAiC;EACvD","sourcesContent":["/* Dans votre fichier CSS */\n.row-custom {\n    margin-left: -8px; /* ou ajustez selon vos besoins */\n    margin-right: -8px; /* ou ajustez selon vos besoins */\n  }\n  \n  .col-custom {\n    padding-left: 8px; /* ou ajustez selon vos besoins */\n    padding-right: 8px; /* ou ajustez selon vos besoins */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
