// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-input-group-text {
  border: none !important;
  background-color: transparent;
}

.custom-form-control:focus {
  box-shadow: none !important;
  border-color: #ced4da !important;
  outline: none !important;
}

.custom-form-control {
  box-shadow: none !important;
  border-color: #ced4da !important;
  border: none !important;
  background-color: transparent;
  font-size: 13px !important;
  border-radius: 0;
}

.list-group-item-active {
  background-color: #61CE70 !important;
  cursor: pointer !important;
  border-color: #333 !important;
}

.deleteName {
  color: #F00020 !important;
  font-weight: 100 !important;
}`, "",{"version":3,"sources":["webpack://./src/screens/Agent/style.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,6BAA6B;AAC/B;;AAEA;EACE,2BAA2B;EAC3B,gCAAgC;EAChC,wBAAwB;AAC1B;;AAEA;EACE,2BAA2B;EAC3B,gCAAgC;EAChC,uBAAuB;EACvB,6BAA6B;EAC7B,0BAA0B;EAC1B,gBAAgB;AAClB;;AAEA;EACE,oCAAoC;EACpC,0BAA0B;EAC1B,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,2BAA2B;AAC7B","sourcesContent":[".custom-input-group-text {\n  border: none !important;\n  background-color: transparent;\n}\n\n.custom-form-control:focus {\n  box-shadow: none !important;\n  border-color: #ced4da !important;\n  outline: none !important;\n}\n\n.custom-form-control {\n  box-shadow: none !important;\n  border-color: #ced4da !important;\n  border: none !important;\n  background-color: transparent;\n  font-size: 13px !important;\n  border-radius: 0;\n}\n\n.list-group-item-active {\n  background-color: #61CE70 !important;\n  cursor: pointer !important;\n  border-color: #333 !important;\n}\n\n.deleteName {\n  color: #F00020 !important;\n  font-weight: 100 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
